$color-text: #231F20;

$icon-font-path: "/fonts/bootstrap/";
$fa-font-path:   "/fonts/fontawesome";

$text-color: $color-text;

$font-family-base: 'Open Sans', sans-serif;

@import "bootstrap";
@import "font-awesome";

body .navbar-brand {
  padding: 0; }


body .navbar-brand img {
  height: 49px; }
